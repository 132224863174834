import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Vimeo = makeShortcode("Vimeo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` method confuses a lot of people. There are numerous Twitter threads lamenting its difficulty, some going so far as to say "Never use it!" `}<strong parentName="p">{`I'm not one to say that.`}</strong></p>
    <p>{`I think `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` is the `}<em parentName="p">{`most powerful`}</em>{` array method there is. In fact, I think you can learn `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` so well, that you will realize it's the only method you really need.`}</p>
    <p>{`You can do `}<em parentName="p">{`literally anything`}</em>{` to an array with it. Every array method can be accomplished using `}<inlineCode parentName="p">{`reduce`}</inlineCode>{`, and we're going to prove that in this course. Seriously, check out the "Course Strategy" down below.`}</p>
    <p>{`I think that once you learn and master `}<inlineCode parentName="p">{`reduce`}</inlineCode>{`, where you're comfortable enough with it to implement the other array methods with it, you'll have more than enough knowledge and skill to decide for yourself whether or not to use `}<inlineCode parentName="p">{`reduce`}</inlineCode>{`. Let's make these decisions from a place of confidence, not a place of fear.`}</p>
    <h3>{`Course Rundown`}</h3>
    <p>{`I am `}<em parentName="p">{`so`}</em>{` excited for you and this course. `}<strong parentName="p">{`Every lesson`}</strong>{` contains a full written explanation of the content. This isn't just a verbatim transcript of a video. Think of it like a full blog post `}<em parentName="p">{`per`}</em>{` lesson. In addition to the written content, almost all of the lessons have one or `}<em parentName="p">{`more`}</em>{` videos that go with them as well. Whether you like written or visual content, I've got you covered.`}</p>
    <p>{`This course is primarily exercise driven. After an intro lesson or two to introduce `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` conceptually, we're going to get into `}<em parentName="p">{`a lot`}</em>{` exercises.`}</p>
    <p>{`The way I learned `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` is the way I'm going to teach you: we will implement a whole lot of functions, including many of the `}<inlineCode parentName="p">{`Array.prototype`}</inlineCode>{` methods, using `}<inlineCode parentName="p">{`reduce`}</inlineCode>{`. Functions like `}<inlineCode parentName="p">{`sum`}</inlineCode>{` and `}<inlineCode parentName="p">{`product`}</inlineCode>{`, and methods like `}<inlineCode parentName="p">{`map`}</inlineCode>{`, `}<inlineCode parentName="p">{`filter`}</inlineCode>{`, `}<inlineCode parentName="p">{`every`}</inlineCode>{`, `}<inlineCode parentName="p">{`includes`}</inlineCode>{`, etc. Repetition will hopefully drive the concept home.`}</p>
    <p>{`We're going to do this using "test driven development" (TDD). Each lesson, we will write tests to prove that our functions work as we expect. My hope is that the work of writing and passing tests will help you see more clearly how the `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` function works with each iteration.`}</p>
    <p>{`Also, in addition to doing each exercise with `}<inlineCode parentName="p">{`reduce`}</inlineCode>{`, we will also implement each exercise using `}<inlineCode parentName="p">{`for`}</inlineCode>{` loops. My hope is that this will also help drive the concept home by creating a mental map between the declarative and imperative versions of the code.`}</p>
    <h3>{`Sample Lesson`}</h3>
    <p>{`Want to get a feel for what a lesson might be like? Check out the `}<inlineCode parentName="p">{`find`}</inlineCode>{` lesson from this course.`}</p>
    <Vimeo src="https://player.vimeo.com/video/611101991?h=b908fc79c0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="Implement `find` with `reduce`" mdxType="Vimeo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import React from 'react'
import { bs } from '../shevy'
import { mq } from '../utils'
import BannerHeader from './BannerHeader'
import ShiftBy from './ShiftBy'

export default function ArrayReduceBanner({ variant }) {
  return (
    <BannerHeader variant={variant}>
      <ShiftBy y={-6}>
        <h2
          css={{
            fontSize: '2.5rem',
            fontWeight: 'bold',
            lineHeight: 1,
            marginBottom: bs(0.25),

            [mq.bravo]: {
              fontSize: '3.5rem',
            },
          }}
        >
          Array.reduce()
        </h2>
        <div
          css={{
            fontFamily: 'var(--fonts-catamaran)',
            fontSize: '1.5rem',
            lineHeight: 1.2,
          }}
        >
          Learn `reduce` once and for all
        </div>
      </ShiftBy>
    </BannerHeader>
  )
}

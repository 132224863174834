import { Link } from 'gatsby'
import React from 'react'
import { useAuthContext } from '../contexts/AuthContext'
import { usePurchaseContext } from '../contexts/PurchasesContext'
import { bs } from '../shevy'

const BUY_TEXT = 'Get the course!'

export default function PurchaseProcess({
  courseId,
  courseLink,
  purchaseLink,
}) {
  const [authState] = useAuthContext()
  const [, purchases] = usePurchaseContext()
  const ownsTheCourse =
    purchases.length && purchases.map(p => p.course_id).includes(courseId)

  switch (true) {
    case authState === 'AUTHENTICATED':
      return ownsTheCourse ? (
        <GoToCourseLink courseLink={courseLink} />
      ) : (
        <PurchasePageLink purchaseLink={purchaseLink} />
      )

    case authState === 'NOT_AUTHENTICATED':
      return <SignUpFirstLink courseId={courseId} />

    default:
      return null
  }
}

const buyCourseStyles = {
  display: 'block',
  backgroundColor: 'var(--colors-contra)',
  borderRadius: 4,
  color: 'white',
  fontFamily: 'var(--fonts-catamaran)',
  fontSize: '1.75rem',
  lineHeight: 1.2,
  padding: bs(),
  marginBottom: bs(),
  textAlign: 'center',
  transition: 'background-color .3s ease',
  width: '100%',

  '&:hover': {
    backgroundColor: 'var(--colors-contraLight)',
    color: 'white',
  },
}

function BigLink({ children, to }) {
  return (
    <Link css={buyCourseStyles} to={to}>
      {children}
    </Link>
  )
}

function GoToCourseLink({ courseLink }) {
  return <BigLink to={courseLink}>Go to the course</BigLink>
}

function SignUpFirstLink({ courseId }) {
  return (
    // TODO: the params don't do shit
    <BigLink to={`/signup?courseId=${courseId}&next=purchase`}>
      {BUY_TEXT}
    </BigLink>
  )
}

function PurchasePageLink({ purchaseLink }) {
  return <BigLink to={purchaseLink}>{BUY_TEXT}</BigLink>
}

import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Content from './content'
import MainInner from '../MainInner'
import Seo from '../Seo'
import shevy, { bs } from '../../shevy'
import { mq } from '../../utils'
import PurchaseProcess from '../PurchaseProcess'
import ArrayReduceBanner from '../ArrayReduceBanner'
import Spacer from '../Spacer'

const query = graphql`
  query GetArrayReduceCourseAndLessons {
    coursesJson(id: { eq: "array-reduce" }) {
      categories
      description
      price {
        max
        min
      }
      published
      title
    }

    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/array-reduce/lessons/" }
        frontmatter: { published: { eq: true } }
      }
      sort: { fields: fileAbsolutePath, order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
          }
        }
      }
    }
  }
`

export default function ArrayReduce() {
  const data = useStaticQuery(query)
  const { price } = data.coursesJson
  const lessons = data.allMdx.edges.map(edge => edge.node)

  return (
    <>
      <Seo title="Array.reduce()" />
      <article>
        <ArrayReduceBanner />
        <MainInner>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: bs(2),

              [mq.charlie]: {
                display: 'grid',
                gridTemplateColumns: '2fr 1fr',
              },
            }}
          >
            <section>
              <Content />
            </section>
            <div>
              <div css={{ [mq.charlie]: { position: 'sticky', top: bs() } }}>
                <Sidebar
                  courseId="array-reduce"
                  lessons={lessons}
                  price={price}
                />
              </div>
            </div>
          </div>
        </MainInner>
      </article>
    </>
  )
}

function Sidebar({ courseId, lessons, price }) {
  return (
    <div>
      <Spacer bottom={1}>
        <div
          css={{
            backgroundColor: 'var(--colors-offset)',
            fontSize: shevy.h4.fontSize,
            fontFamily: 'var(--fonts-catamaran)',
            padding: bs(0.5),
            textAlign: 'center',
          }}
        >
          Price: {price.min}-{price.max} USD. You choose!
        </div>
      </Spacer>

      <PurchaseProcess
        courseId={courseId}
        courseLink="/learn/array-reduce"
        purchaseLink="/courses/array-reduce/purchase"
      />

      <Lessons lessons={lessons} />
    </div>
  )
}

function Lessons({ lessons }) {
  return (
    <section
      css={{
        [mq.charlie]: {
          backgroundColor: 'var(--colors-offset)',
          padding: bs(),
        },
      }}
    >
      <h3>Lessons</h3>
      <ol
        css={{
          fontFamily: 'var(--fonts-catamaran)',
          listStyle: 'decimal inside',
          marginBottom: 0,

          'li + li': { marginTop: bs(0.25) },
        }}
      >
        {lessons.map(lesson => {
          const {
            id,
            frontmatter: { title },
          } = lesson

          return (
            <li css={{}} key={id}>
              {title}
            </li>
          )
        })}
      </ol>
    </section>
  )
}

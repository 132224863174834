import React from 'react'
import MaxWidth from './MaxWidth'
import { bs } from '../shevy'

const variantStyles = {
  big: {
    padding: `${bs(4)} ${bs(2)}`,
  },
  small: {
    padding: `${bs()} ${bs(2)}`,
  },
}

export default function BannerHeader({ children, variant = 'big' }) {
  return (
    <header
      css={{
        backgroundImage:
          'linear-gradient(30deg, var(--colors-accentDark), var(--colors-accent), var(--colors-accentDark))',
        color: 'hsl(0 0% 100%)',
        overflow: 'hidden',
        position: 'relative',

        '&:before': {
          backgroundColor: 'var(--colors-accentLight)',
          boxShadow: '0 0 8px hsla(0, 0%, 0%, 0.25)',
          content: '""',
          display: 'block',
          height: '100%',
          width: '60%',
          position: 'absolute',
          top: 0,
          right: 0,
          transform: 'translateX(50%) skewX(290deg)',
        },

        '&:after': {
          backgroundColor: 'var(--colors-accentDark)',
          boxShadow: '0 0 8px hsla(0, 0%, 0%, 0.75)',
          content: '""',
          display: 'block',
          height: '100%',
          width: '50%',
          position: 'absolute',
          top: 0,
          right: 0,
          transform: 'translateX(50%) skewX(55deg)',
        },
      }}
    >
      <MaxWidth>
        <div
          css={{
            padding: variantStyles[variant].padding,
            position: 'relative',
            zIndex: 1,
          }}
        >
          {children}
        </div>
      </MaxWidth>
    </header>
  )
}
